import React, { useEffect } from 'react'
import birdWithCase from '../../../assets/images/birdWithCase.png'
import bouncingArrowSmaller from '../../../assets/images/bouncingArrow-smaller.gif'

function CloseHomePage () {
  useEffect(() => {
    const formAdded = document.querySelector('iframe[title="SubmitEmailClose"]')

    if (!formAdded) {
      const script = document.createElement('script')

      script.src = 'https://form.jotform.com/jsform/241368962327060'
      script.async = true

      document.body.appendChild(script)
    } else {
      formAdded.style.display = 'block'
    }

    return () => {
      const jotFormFrame = document.querySelector('iframe[title="SubmitEmailClose"]')

      if (jotFormFrame) {
        jotFormFrame.style.display = 'none'
      }
    }
  }, [])

  return (
    <div className='top-container' style={{ color: '#00322E' }}>
      <div
        style={{
          color: '#00322E',
          textAlign: 'center',
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: 'ABC Monument Grotesk Black',
          fontSize: '30px',
          lineHeight: '110%',
          letterSpacing: '0.25px',
          height: '99px'
        }}
      >
        <p>Little Birdie has been</p>
        <p style={{ lineHeight: '115%' }}><span style={{ color: '#239F55' }}>acquired</span> by Cashrewards</p>
      </div>
      <div style={{ display: 'flex' }}>
        <img src={birdWithCase} alt='Little Birdie with a suitcase' style={{ width: '228px', margin: 'auto' }} />
      </div>
      <div className='close-description-container'>
        <div className='close-description'>
          <p
            style={{
              fontFamily: 'ABC Monument Grotesk Bold',
              fontSize: '18px',
              lineHeight: '133.4%',
              marginTop: '28px'
            }}
          >
            Exciting News, Little Birdie Lovers!
          </p>
          <div
            style={{
              fontFamily: 'ABC Monument Grotesk',
              fontSize: '18px',
              lineHeight: '133.4%'
            }}
          >
            <p>We're thrilled to announce that Little Birdie has been acquired by <a href='https://www.cashrewards.com.au' style={{ color: '#239F55', textDecoration: "underline" }}>Cashrewards.com.au</a>, Australia's leading cashback platform! While we're taking a short break to spruce up our feathers, we'll be back soon with even more ways to save you money.</p>
            <p><strong>What does this mean for you as a member?</strong></p>
            <p>We are busy working in the background to get the Little Birdie you know and love back up and running so you can go back to using all the features you valued most. Stay tuned for our grand re-launch coming soon. In the meantime, keep an eye on our socials or add your email below to be the first to know when we're back!</p>
            <p>We can't wait to welcome you back to the Little Birdie nest with even more ways to save!</p>
            <p>Chirp Chirp,<br />The Little Birdie and Cashrewards Team</p>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <img src={bouncingArrowSmaller} style={{ height: '24px', width: '24px' }} />
      </div>
      <div
        style={{
          marginTop: '33px',
          textAlign: 'center',
          fontFamily: 'ABC Monument Grotesk Heavy',
          fontSize: '24px',
          lineHeight: '133.4%'
        }}
      >
        Stay tuned for what's next
      </div>
    </div>
  )
}

export default CloseHomePage
