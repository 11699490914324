import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Typewriter from 'typewriter-effect'

import {
  // aboutIcon,
  // categoryIcon,
  searchIconGreen,
  upsellDesk,
  // cloudImage
} from '../../../components/Icons'

import { showUserSlider, showCatSlider, setSearchActive } from '../actions'
import { getIsSearchInputActive, getIsMobile } from '../reducers'
import { isTablet } from '../utilis/detectBrowser'
import { LB_APP_TAB_BAR_VERSION, LB_NATIVE_APP_VERSION } from '../../../../constant'

function HomepageSearch(props) {
  const dispatch = useDispatch()
  const [birdAnimationClass, setBirdAnimationClass] = useState('')
  const inputState = useSelector(getIsSearchInputActive)
  const isMobile = useSelector(getIsMobile)
  const isWebWView = !!window?.ReactNativeWebView && LB_NATIVE_APP_VERSION >= LB_APP_TAB_BAR_VERSION

  useEffect(() => {
    inputState && setBirdAnimationClass('')
    const ovrlayFlyInTimer = setTimeout(() => {
      !inputState && setBirdAnimationClass('bird-fly-in')
    }, 10)
    const overlayTimer = setTimeout(() => {
      !inputState && setBirdAnimationClass('bird-main-move')
    }, 3000)

    return () => {
      clearTimeout(ovrlayFlyInTimer)
      clearTimeout(overlayTimer)
    }
  }, [inputState])

  function handleSearchInputFocus() {
    window.scrollTo(0, 0)
    const scrollTimer = setTimeout(() => dispatch(setSearchActive(true)), 50)
  }

  return (
    <div className='homesearch-section'>
      {/* <img className='cloud-img' src={cloudImage} /> */}
      <div className='d-flex homepage-search justify-content-center'>
        <div className='d-flex flex-column search-content'>
          <div className={`d-flex flex-column msg-text bird-animation ${isWebWView ? 'bird-fly-web-view' : birdAnimationClass}`}>
            <div className={`personal-msg ${isWebWView ? 'remove-bottom-space' : ''}`}>
              <div className='d-block'>
                <span className='lb-budgee-text'>Compare</span>
                <span> prices</span>
              </div>
              <div className='d-none d-md-block'>
                <span>across</span>
                <span className='lb-budgee-text'> the internet</span>
              </div>
              <div className='d-md-none d-block'>
                <span>across</span>
                <span className='lb-budgee-text'> the</span>
                {isWebWView && <span className='d-md-none lb-budgee-text'> internet</span> || null }
              </div>
                {!isWebWView && <span className='d-md-none lb-budgee-text'> internet</span> || null }
              <span className='pre-load' />
            </div>
          </div>
          {
            !isWebWView && (
              <div className={`search-bar ${inputState && 'input-focus'}`}>
                <div className='text-animation' onClick={() => { handleSearchInputFocus() }}>
                  {
                    !inputState &&
                    <Typewriter
                      options={{
                        strings: ['iPhone 14', 'Dyson'],
                        autoStart: true,
                        loop: true
                      }}
                    />
                  }
                </div>
                <div className={`input-group homepage-search-input-group ${inputState && 'invisible'}`}>
                  <input
                    aria-label='staticSearch'
                    aria-describedby='static-search-submit-btn'
                    className='form-control'
                    value=''
                    readOnly
                    type='text'
                    onFocus={() => { handleSearchInputFocus() }}
                  />
                  <div className='search-icon-img'>
                    <img src={searchIconGreen} />
                  </div>
                </div>
                <div className={` ${!inputState && 'd-none'} focus-overlay`} />
              </div>
            ) || null
          }
          <div className='search-footer'>
            <div className='product-msg'>Get the best price from 1000’s of stores</div>
          </div>
        </div>
      </div>
      {!isMobile && !isTablet && <div className='container-fluid upsell-banner'>
        <img onClick={() => window.open('https://chrome.google.com/webstore/detail/little-birdie-get-the-bes/eclmkceplokololbkleieojfomhcicdf')} src={upsellDesk} />
      </div>}
    </div>
  )
}

export default HomepageSearch
