import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react'
import '@builder.io/widgets'
import { useEffect, useState } from 'react'
import InvalidRoute from '../../../../../components/utils/InvalidRoute'
import { gon } from '../../../../../../constant'
import './RegisterComponents'

builder.init(gon.builder_io.key)

// let cachebustPages = [
//   '/home-ext-disabled',
//   '/home-ext-enabled',
//   '/home',
//   '/'
// ]

const removeTrailingSlash = (url) => {
  return url.replace(/\/$/, '');
}

export default function BuilderIOConfig() {
  const isPreviewingInBuilder = useIsPreviewing()
  const [notFound, setNotFound] = useState(false)
  const [content, setContent] = useState(null)

  // get the page content from Builder
  useEffect(() => {
    setContent('')
    async function fetchContent() {
      const pathname = removeTrailingSlash(window.location.pathname)
      // const isHomePage = cachebustPages.indexOf(pathname) > -1

      const content = await builder.get('page', {
        url: pathname,
        // cachebust: isHomePage,
      }).promise()

      setContent(content)
      setNotFound(!content)
    }
    fetchContent()
  }, [window.location.pathname])
  // if no page is found, return a page not found
  if (notFound && !isPreviewingInBuilder) {
    return <InvalidRoute />
  }

  // return the page when found
  return (
    <div className='container-fluid full-width-container builder-container'>
      {content && <BuilderComponent model='page' content={content} options={{  }} />}
    </div>
  )
}
